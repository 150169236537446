<template>
  <div class="chart">
    <highcharts
      ref="chartComponent"
      :options="chartOptions"
      :update-args="updateArgs"
    />
    <div class="text-center">
      <template v-if="selectSector > 0">
        Сектор: {{ selectSector }}
        <el-row>
          <el-col :span="4" :offset="10">
            <el-color-picker
              v-model="sectorColor"
              color-format="rgb"
              :predefine="[
                '#409EFF',
                '#1890ff',
                '#304156',
                '#212121',
                '#11a983',
                '#13c2c2',
                '#6959CD',
                '#f5222d',
              ]"
              class="sector-color-picker"
            />
            <el-input v-model="newNameSector" label="Name" />
            <el-button class="margin-10" type="primary" @click="updateSector">
              Обновить сектор
            </el-button>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="4" :offset="10">
            <el-button class="margin-10" type="primary" @click="addSection">
              Добавить компетенцию
            </el-button>
          </el-col>
        </el-row>
      </template>
      <template v-else-if="selectLevel > 0">
        Уровень: {{ selectLevel }}
        <el-button type="danger" @click="removeLevel">
          Удалить уровень
        </el-button>
      </template>
      <template v-else-if="selectSection > 0">
        Секция: {{ selectSection }}
        <el-row>
          <el-col :span="4" :offset="10">
            <el-input v-model="newNameSection" label="Name" />
            <el-checkbox
              v-if="showNotificationCheckbox"
              v-model="useNotification"
              label="Оповестить пользователей о новой компетенции"
            />
            <el-button type="primary" @click="updateSection">Update</el-button>
            <el-button class="margin-10" type="danger" @click="removeSection">
              Удалить компетенцию
            </el-button>
          </el-col>
        </el-row>
      </template>
      <template v-else-if="selectDegree > 0">
        Степень: {{ selectDegree }}
        <el-button type="primary" @click="addLevel">
          Добавить уровень
        </el-button>
      </template>
      <template v-else-if="selectCell !== null">
        Ячейка: {{ selectCell.custom.id }}
      </template>
    </div>
  </div>
</template>

<script>
// import Highcharts from 'highcharts'
import { Chart } from "highcharts-vue";
import { updateSector, updateSection } from "@/api/competence.js";
export default {
  components: {
    highcharts: Chart,
  },
  props: {
    series: {
      type: Array,
      default: function() {
        return [];
      },
    },
    selectType: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      updateArgs: [true, true, { duration: 1000 }],
      selectSector: 0,
      selectLevel: 0,
      selectSection: 0,
      selectDegree: 0,
      selectCell: null,
      newNameSection: "",
      newNameSector: "",
      sectorColor: "",
      degreeColor: "",
      showNotificationCheckbox: false,
      useNotification: true,
      // chartOptions: {
      //   chart: {
      //     plotBackgroundColor: null,
      //     plotBorderWidth: 0,
      //     plotShadow: false
      //   },
      //   title: {
      //     text: 'Browser<br>shares<br>2017',
      //     align: 'center',
      //     verticalAlign: 'middle'
      //   },
      //   tooltip: {
      //     pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
      //   },
      //   plotOptions: {
      //     pie: {
      //       dataLabels: {
      //         enabled: true
      //       }
      //     }
      //   },
      //   series: this.series
      // }
    };
  },
  computed: {
    chartOptions() {
      return {
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: 0,
          plotShadow: false,
          height: "30%",
          backgroundColor: "rgba(0,0,0,0)",
        },
        legend: {
          verticalAlign: "top",
        },
        title: {
          text: "Конструктор компетенций",
          // align: 'center',
          // verticalAlign: 'middle'
        },
        tooltip: {
          pointFormat: "",
        },
        colors: ["#ffffff"],
        plotOptions: {
          pie: {
            // borderColor: '#1A4780',
            borderColor: "#ffffff",
            allowPointSelect: true,
            dataLabels: {
              enabled: false,
            },
            slicedOffset: 0,
            states: {
              hover: {
                enabled: false,
              },
              inactive: {
                opacity: 1,
              },
            },
            point: {
              events: {
                click: (event) => {
                  this.selectCallback(event);
                },
                legendItemClick: (e) => {
                  return false;
                },
                // unselect: (event) => { this.unselectCallback(event) }
              },
            },
          },
        },
        series: this.series,
      };
    },
  },
  watch: {
    selectType() {
      this.$refs.chartComponent.chart.series.forEach((element) =>
        element.data.forEach((item) => {
          item.color = item.custom.color;
          item.select(false);
        })
      );
      this.selectSector = 0;
      this.selectLevel = 0;
      this.selectSection = 0;
      this.selectDegree = 0;
      this.selectCell = null;
      this.$refs.chartComponent.chart.redraw();
    },
  },
  methods: {
    addSection() {
      this.$refs.chartComponent.chart.series.forEach((element) =>
        element.data.forEach((item) => {
          item.color = item.custom.color;
          item.select(false);
        })
      );
      this.$emit(
        "add-section",
        this.selectSector,
        this.sectorColor.slice(0, 3) +
          "a" +
          this.sectorColor.slice(3, -1) +
          ",0.5)",
        this.newNameSector
      );
      this.selectSector = 0;
      this.selectLevel = 0;
      this.selectSection = 0;
      this.selectDegree = 0;
      this.selectCell = null;
    },
    removeSection() {
      this.$refs.chartComponent.chart.series.forEach((element) =>
        element.data.forEach((item) => {
          item.color = item.custom.color;
          item.select(false);
        })
      );
      this.$emit("remove-section", this.selectSection);
      this.selectSector = 0;
      this.selectLevel = 0;
      this.selectSection = 0;
      this.selectDegree = 0;
      this.selectCell = null;
    },
    addLevel() {
      this.$refs.chartComponent.chart.series.forEach((element) =>
        element.data.forEach((item) => {
          item.color = item.custom.color;
          item.select(false);
        })
      );
      this.$emit(
        "add-level",
        this.selectDegree,
        this.degreeColor.slice(0, 3) +
          "a" +
          this.degreeColor.slice(3, -1) +
          ",0.5)"
      );
      this.selectSector = 0;
      this.selectLevel = 0;
      this.selectSection = 0;
      this.selectDegree = 0;
      this.selectCell = null;
    },
    removeLevel() {
      this.$refs.chartComponent.chart.series.forEach((element) =>
        element.data.forEach((item) => {
          item.color = item.custom.color;
          item.select(false);
        })
      );
      this.$emit("remove-level", this.selectLevel);
      this.selectSector = 0;
      this.selectLevel = 0;
      this.selectSection = 0;
      this.selectDegree = 0;
      this.selectCell = null;
    },
    // unselectCallback(event) {
    //   // console.log(event.target)
    //   if (this.selectType === 0) {
    //     event.target.color = '#ffffff'
    //   } else if (this.selectType === 1) {
    //     if (event.target.custom.sector_id !== this.selectSector) {
    //       this.$refs.chartComponent.chart.series.forEach(element =>
    //         element.data.forEach(item => {
    //           if (item.custom.sector_id === event.target.custom.sector_id) {
    //             item.color = '#ffffff'
    //           }
    //         })
    //       )
    //     }
    //   } else if (this.selectType === 2) {
    //     if (event.target.custom.level_id !== this.selectLevel) {
    //       this.$refs.chartComponent.chart.series.forEach(element =>
    //         element.data.forEach(item => {
    //           if (item.custom.level_id === event.target.custom.level_id) {
    //             item.color = '#ffffff'
    //           }
    //         })
    //       )
    //     }
    //   } else if (this.selectType === 3) {
    //     if (event.target.custom.degree_id !== this.selectDegree) {
    //       this.$refs.chartComponent.chart.series.forEach(element =>
    //         element.data.forEach(item => {
    //           if (item.custom.degree_id === event.target.custom.degree_id) {
    //             item.color = '#ffffff'
    //           }
    //         })
    //       )
    //     }
    //   } else if (this.selectType === 4) {
    //     if (event.target.custom.section_id !== this.selectSection) {
    //       this.$refs.chartComponent.chart.series.forEach(element =>
    //         element.data.forEach(item => {
    //           if (item.custom.section_id === event.target.custom.section_id) {
    //             item.color = '#ffffff'
    //           }
    //         })
    //       )
    //     }
    //   }
    //   this.$refs.chartComponent.chart.redraw()
    // },
    selectCallback(event) {
      if (this.selectType === 0) {
        if (this.selectCell) {
          if (event.point.custom.id !== this.selectCell.custom.id) {
            this.selectCell.color = this.selectCell.custom.color;
            this.selectCell.select(false);
            this.selectCell = event.point;
            event.point.color = "#ffff00";
            event.point.select(true, false);
          }
        } else {
          this.selectCell = event.point;
          event.point.color = "#ffff00";
          event.point.select(true, false);
        }
      } else if (this.selectType === 1) {
        if (event.point.custom.sector_id !== this.selectSector) {
          this.selectSector = event.point.custom.sector_id;
          this.newNameSector = event.point.name;
          this.sectorColor =
            event.point.custom.color.slice(0, 3) +
            event.point.custom.color.slice(4, -5) +
            ")";
          this.$refs.chartComponent.chart.series.forEach((element) =>
            element.data.forEach((item) => {
              if (item.custom.sector_id === event.point.custom.sector_id) {
                item.color = "#ffff00";
                item.select(true, true);
              } else {
                item.color = item.custom.color;
                item.select(false);
              }
            })
          );
        }
      } else if (this.selectType === 2) {
        if (event.point.custom.level_id !== this.selectLevel) {
          this.selectLevel = event.point.custom.level_id;
          this.$refs.chartComponent.chart.series.forEach((element) =>
            element.data.forEach((item) => {
              if (item.custom.level_id === event.point.custom.level_id) {
                item.color = "#ffff00";
                item.select(true, true);
              } else {
                item.color = item.custom.color;
                item.select(false);
              }
            })
          );
        }
      } else if (this.selectType === 3) {
        if (event.point.custom.degree_id !== this.selectDegree) {
          this.selectDegree = event.point.custom.degree_id;
          this.degreeColor =
            event.point.custom.color.slice(0, 3) +
            event.point.custom.color.slice(4, -5) +
            ")";
          this.$refs.chartComponent.chart.series.forEach((element) =>
            element.data.forEach((item) => {
              if (item.custom.degree_id === event.point.custom.degree_id) {
                item.color = "#ffff00";
                item.select(true, true);
              } else {
                item.color = item.custom.color;
                item.select(false);
              }
            })
          );
        }
      } else if (this.selectType === 4) {
        if (event.point.custom.section_id !== this.selectSection) {
          this.selectSection = event.point.custom.section_id;
          this.newNameSection = event.point.custom.nameSection;
          this.showNotificationCheckbox = this.newNameSection === "Секция";
          this.useNotification = this.showNotificationCheckbox == true;
          this.$refs.chartComponent.chart.series.forEach((element) =>
            element.data.forEach((item) => {
              if (item.custom.section_id === event.point.custom.section_id) {
                item.color = "#ffff00";
                item.select(true, true);
              } else {
                item.color = item.custom.color;
                item.select(false);
              }
            })
          );
        }
      }
      this.$refs.chartComponent.chart.redraw();
    },
    updateSection() {
      updateSection(this.selectSection, {
        name: this.newNameSection,
        notify: this.useNotification,
      }).then(() => {
        // this.$refs.chartComponent.chart.series.forEach(element => {
        //   element.data.forEach(item => {
        //     if (item.custom.section_id === this.selectSection) {
        //       item.name = this.newNameSection
        //       item.update({ name: this.newNameSection })
        //     }
        //   })
        // })
        this.series.forEach((element) => {
          if (element.custom.section_id === this.selectSection) {
            element.custom.name = this.newNameSection;
            if (element.dataLabels) {
              element.dataLabels.format = this.newNameSection;
            }
            element.data.forEach((item) => {
              item.custom.nameSection = this.newNameSection;
            });
          }
        });
        this.$refs.chartComponent.chart.series.forEach((element) =>
          element.data.forEach((item) => {
            item.color = item.custom.color;
            item.select(false);
          })
        );
        this.selectSector = 0;
        this.selectLevel = 0;
        this.selectSection = 0;
        this.selectDegree = 0;
        this.selectCell = null;
        this.$refs.chartComponent.chart.redraw();
      });
      // this.$refs.chartComponent.chart.reflow()
    },
    updateSector() {
      updateSector(this.selectSector, {
        name: this.newNameSector,
        color:
          this.sectorColor.slice(0, 3) +
          "a" +
          this.sectorColor.slice(3, -1) +
          ",0.5)",
      }).then(() => {
        this.$refs.chartComponent.chart.series.forEach((element) =>
          element.data.forEach((item) => {
            if (item.custom.sector_id === this.selectSector) {
              item.name = this.newNameSector;
              item.color =
                this.sectorColor.slice(0, 3) +
                "a" +
                this.sectorColor.slice(3, -1) +
                ",0.5)";
              item.custom.color =
                this.sectorColor.slice(0, 3) +
                "a" +
                this.sectorColor.slice(3, -1) +
                ",0.5)";
              item.update({
                name: this.newNameSector,
                color:
                  this.sectorColor.slice(0, 3) +
                  "a" +
                  this.sectorColor.slice(3, -1) +
                  ",0.5)",
                custom: {
                  color:
                    this.sectorColor.slice(0, 3) +
                    "a" +
                    this.sectorColor.slice(3, -1) +
                    ",0.5)",
                },
              });
            }
          })
        );
        this.series.forEach((element) =>
          element.data.forEach((item) => {
            if (item.custom.sector_id === this.selectSector) {
              item.name = this.newNameSector;
              item.color =
                this.sectorColor.slice(0, 3) +
                "a" +
                this.sectorColor.slice(3, -1) +
                ",0.5)";
              item.custom.color =
                this.sectorColor.slice(0, 3) +
                "a" +
                this.sectorColor.slice(3, -1) +
                ",0.5)";
            }
          })
        );
        this.$refs.chartComponent.chart.series.forEach((element) =>
          element.data.forEach((item) => {
            item.color = item.custom.color;
            item.select(false);
          })
        );
        this.selectSector = 0;
        this.selectLevel = 0;
        this.selectSection = 0;
        this.selectDegree = 0;
        this.selectCell = null;
        this.$refs.chartComponent.chart.redraw();
      });
    },
    addPoint(value, sector_id) {
      // for (var i = 0; i < this.$refs.chartComponent.chart.series.length; i++) {
      //   this.$refs.chartComponent.chart.series[i].addPoint(value)
      // }
      //
      // this.$refs.chartComponent.chart.series.forEach(element =>
      //   element.addPoint(value)
      // )
      var index = 0;
      this.$refs.chartComponent.chart.series.forEach((element) => {
        if (element.userOptions.custom.sector_id === sector_id) {
          element.addPoint(value[index]);
          index = index + 1;
        }
      });
    },
    removePoint(index, sector_id) {
      this.$refs.chartComponent.chart.series.forEach((element) => {
        if (element.userOptions.custom.sector_id === sector_id) {
          element.removePoint(index);
        }
      });
      // for (var i = 0; i < this.$refs.chartComponent.chart.series.length; i++) {
      //   this.$refs.chartComponent.chart.series[i].removePoint(index)
      // }
    },
    addSeries(value) {
      this.$refs.chartComponent.chart.addSeries(value);
    },
    removeSeries(index) {
      this.$refs.chartComponent.chart.series[index].remove();
    },
  },
  // data() {
  //   return {
  //     chartOptions: {
  //       chart: {
  //         plotBackgroundColor: null,
  //         plotBorderWidth: 0,
  //         plotShadow: false
  //       },
  //       title: {
  //         text: 'Browser<br>shares<br>2017',
  //         align: 'center',
  //         verticalAlign: 'middle'
  //       },
  //       tooltip: {
  //         pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
  //       },
  //       plotOptions: {
  //         pie: {
  //           dataLabels: {
  //             enabled: true
  //           }
  //         }
  //       },
  //       series: [{
  //         type: 'pie',
  //         name: 'Browser share',
  //         innerSize: '50%',
  //         states: {
  //           hover: {
  //             enabled: false
  //           },
  //           inactive: {
  //             opacity: 1
  //           }
  //         },
  //         data: [
  //           ['Chrome', 58.9],
  //           ['Firefox', 13.29],
  //           ['Internet Explorer', 13],
  //           ['Edge', 3.78],
  //           ['Safari', 3.42]
  //         ]
  //       },
  //       {
  //       type: 'pie',
  //       name: 'Browser share',
  //       states: {
  //         hover: {
  //           enabled: false
  //         },
  //         inactive: {
  //           opacity: 1
  //         }
  //       },
  //       innerSize: '75%',
  //       data: [
  //         ['Chrome', 58.9],
  //         ['Firefox', 13.29],
  //         ['Internet Explorer', 13],
  //         ['Edge', 3.78],
  //         ['Safari', 3.42]
  //       ]
  //     }]
  //     }
  //   }
  // }
};
</script>
<style scoped>
.width-100 {
  width: 100px;
}
.margin-10 {
  margin-bottom: 10px;
}
</style>
